<template>
  <div class="ui-page flex-row flex-center">
    <div>
        <div style="text-align: center;margin-bottom: 10px;"><van-loading size="44" /></div>
        <div>正在登录，请稍候...</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import querystring from 'querystring'
import wxApi from '../utils/wxApi'

export default {
    computed: {
        token() {
            return this.$store.state.token
        }
    },
    created() {
        if(this.token) {
            this.$router.replace('/my-rooms')
        } else {
            let search = window.location.search
            if(search) {
                let query = querystring.parse(search.substr(1))

                this.$axios.get('/api/weChatMini/v1/user/validateLogin', {
                    params: {
                        code: query.code
                    }
                }).then(res => {
                    if(res.data) {
                        this.$store.commit('login', {
                            userId: res.data.userId,
                            userType: res.data.userType,
                            userMode: 1,
                            token: res.data.token,
                            refreshToken: res.data.refreshToken,
                            expireTime: res.data.expire * 1000 + new Date().getTime()
                        })
                        this.$router.replace('/my-rooms')
                    } else {
                        this.$router.replace('/bind-mobile')
                    }
                })
                .catch(err => {
                    this.$dialog.alert({
                        message: err.message
                    })
                })
            }
        }

    }
}
</script>